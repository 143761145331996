import React from "react";
import "./List.scss";
import Card from "../Card/Card";
import useFetch from "../../hooks/useFetch";

const List = ({ subCats, maxPrice, sort, catId, flov }) => {
  let query = `/products?populate=*`;

  // Always filter by main category
  query += `&[filters][categories][id][$eq]=${catId}`;

  // Add sub-category filters if selected
  if (subCats.length > 0) {
    const subCatFilters = subCats
      .map((id) => `&[filters][sub_categories][id][$in]=${id}`)
      .join("");
    query += subCatFilters;
  }

  // Add flavor filters if selected
  if (flov.length > 0) {
    const flovFilters = flov
      .map((id) => `&[filters][flavors][id][$in]=${id}`)
      .join("");
    query += flovFilters;
  }

  // Always apply max price
  query += `&[filters][price][$lte]=${maxPrice}`;

  // Sorting if applicable
  if (sort) {
    query += `&sort=price:${sort}`;
  }

  const { data, loading, error } = useFetch(query);

  return (
    <div className="list">
      {loading ? (
        <div className="cen_wrp">
          <p>Loading...</p>
        </div>
      ) : error ? (
        <div className="cen_wrp">
          <p>Something went wrong. Please try again later.</p>
        </div>
      ) : Array.isArray(data) && data.length === 0 ? (
        <div className="cen_wrp">
          <p>
            No products found for the selected filters. <br /> Please try adjusting
            your filters or explore other categories.
          </p>
        </div>
      ) : Array.isArray(data) ? (
        data.map((item) => <Card item={item} key={item.id} />)
      ) : null}
    </div>
  );
};


export default List;
